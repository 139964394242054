import request from '@/utils/request'

// 查询申请记录列表
export function findOrderEvaluateList(params) {
  return request({
    url: `/order_evaluates`,
    method: 'get',
    params: params
  })
}

// 回复
export function orderEvaluateReply(id, data) {
  return request({
    url: `/order_evaluates/${id}/reply`,
    method: 'post',
    data
  })
}
