<template>
  <div>
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="评价时间">
            <a-range-picker v-decorator="['created_at']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item label="状态">
            <a-select
              v-decorator="['status', { initialValue: 'pending' }]"
              placeholder="请选择状态"
              allow-clear
            >
              <a-select-option value="pending">
                待处理
              </a-select-option>
              <a-select-option value="replied">
                已回复
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item label="用户姓名">
            <a-input
              v-decorator="['name', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入用户姓名"
              autocomplete="off"
              allow-clear
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item label="联系电话">
            <a-input
              v-decorator="['phone_number', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入联系电话"
              autocomplete="off"
              allow-clear
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item label="内容关键字">
            <a-input
              v-decorator="['content', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入评价内容关键字"
              autocomplete="off"
              allow-clear
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'

export default {
  name: 'SearchOrderEvaluates',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'search_order_evaluates' })
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        created_at_begin: formatRangeBeginDate(fieldsValue.created_at),
        created_at_end: formatRangeEndDate(fieldsValue.created_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>

