<template>
  <a-modal
    v-model="isShow"
    :footer="null"
    width="1000px"
    title="预览图片"
  >
    <div v-if="images.length > 0">
      <img style=" margin-bottom: 10px;width: 100%" v-for="(item, index) in images" :key="index" :src="item.url">
    </div>
    <div v-if="image">
      <img :src="image" style="width: 100%;">
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'PreviewImage',
  props: {
    // 是否预览
    visible: {
      type: Boolean,
      default: false
    },

    image: {
      type: String,
      default: ''
    },

    // 预览图片地址
    images: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
  }
}
</script>
