<template>
  <div>
    <search-order-evaluates @submit="submitSearch" />

    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs && imgs.length > 0" class="custom-blue" @click="showImg(record)">查看详情</span>-->
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showDetailModal(record)">回复</a>
          <a @click="showOperationHistoryModal(record.id)">操作历史</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 回复模态框 -->
    <detail-info-modify-apply
      v-if="isShowDetailModal"
      :visible.sync="isShowDetailModal"
      :record="detailingRecord"
      @completed="fetchData"
    />

    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="OrderEvaluate"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchOrderEvaluates from '@/views/order_evaluates/Search'
import { findOrderEvaluateList } from '@/api/order_evaluate'
import PreviewImage from '@/components/PreviewImage'

export default {
  name: 'InfoModifyApplyList',
  components: {
    Pagination,
    SearchOrderEvaluates,
    PreviewImage,
    DetailInfoModifyApply: () => import('@/views/order_evaluates/Detail'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal')
  },
  data() {
    return {
      query: { status: 'pending' },
      data: [],
      loading: true,
      isShowDetailModal: false, // 是否显示详情表单弹窗
      detailingRecord: {}, // 正在编辑的记录id
      previewVisible: false,
      previewImages: [],
      isShowOperationHistoryModal: false,
      showingOperationHistoryId: 0,
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '评价时间',
          dataIndex: 'evaluate_time',
          width: 180,
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'evaluate_time' ? this.sort.sort_order : false
        },
        {
          title: '关联订单',
          width: 250,
          dataIndex: 'order_no'
        },
        {
          title: '订单负责人',
          width: 150,
          dataIndex: 'user_name'
        },
        {
          title: '用户姓名',
          width: 90,
          dataIndex: 'apply_name'
        },
        {
          title: '电话',
          width: 200,
          dataIndex: 'phone_number'
        },
        {
          title: '邮箱',
          width: 150,
          dataIndex: 'email'
        },
        {
          title: '评价星级',
          width: 90,
          dataIndex: 'level'
        },
        {
          title: '评价内容',
          width: 200,
          dataIndex: 'remark'
        },
        {
          title: '照片',
          width: 90,
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'status'
        },
        {
          title: '操作',
          width: 220,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showDetailModal(record) {
      this.detailingRecord = record
      this.isShowDetailModal = true
    },
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findOrderEvaluateList(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
